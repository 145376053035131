import service from './service'

export default {
    // 报名
    setSign(data) {
        return service.post('/apply/info/add',data)
    },
    // 报名列表
    signList(params) {
        return service.get('/apply/info/page?pageNum=' + params.pageNum  + '&pageSize=' + params.pageSize  + '&keyword=' + params.keyword  + '&userId=' + params.userId + '&days=' + params.days + '&school=' + params.school + '&address=' + params.address)
    },

    // 上报气象数据列表
    reportList(params) {
        return service.get('/apply/record/all/page?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize + '&date=' + params.date + '&userId=' + params.userId )
    },

    // 上报气象数据
    reportData(data) {
        return service.post('/apply/record/all/save' , data)
    },
    // 查询报名信息
    getSignDetail(id) {
        return service.get('/apply/info/oneByUserId?userId=' + id)
    },

    // 查询设备列表
    getList(params) {
        return service.get('/connect/list/by/userName?userName=' + params.userName +'&email=' + params.email + '&userId=' + params.userId)
    },

    // 自动获取数据
    getAutoData(params) {
        return service.get('/device_data/newest?device=' + params.device + '&time=' + params.time)
    },
    // 上传乱问小报告
    reportLunwen(data) {
        return service.post('/apply/info/update',data)
    },

    // 统计记录天数
    days(id) {
        return service.get('/apply/record/all/stat?userId=' + id)
    },

    // 评分
    review(data) {
        return service.post('/apply/score/add' ,data)
    },
    // 审核
    audit(data) {
        return service.post('/apply/info/update' ,data)
    },
}